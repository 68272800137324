<template>
  <div
    ref="inputRef"
    :key="roundedScore"
    :class="classes"
  >
    <template v-if="active">
      <template
        v-for="num in total"
        :key="`${num + roundedScore}`"
      >
        <label
          :aria-label="`${num} stars`"
          class="rating__label"
          :class="{ 'rating__label--half': num % 1 !== 0 }"
          :for="`rating-${num * 10}`"
          @click.stop="emitClick(num)"
        >
          <i
            class="rating__icon i-star-yellow"
            :class="{ 'i-star-half': Math.floor(num) !== num }"
          />
        </label>

        <input
          :id="`rating-${num * 10}`"
          class="rating__input"
          :name="`rating-${num}`"
          :value="num"
          type="radio"
          :checked="checkedInput(num)"
        >
      </template>
    </template>
    <template v-else>
      <span
        v-for="num in total"
        :key="`${num + roundedScore}`"
        class="rating__label rating__icon"
        :class="`i-star${roundedScore >= num ? '-yellow' : roundedScore >= num - 0.5 ? '-half' : ''}`"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, computed, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import type { PropType } from 'vue'

export type RatingSize = 'sm' | 'base' | 'lg' | undefined

defineComponent({ name: 'ARating' })

const props = defineProps({
  value: {
    type: Number,
    required: true,
    default: 0,
    validator: (stars: number) => stars >= 0 && stars <= 5
  },
  size: {
    type: String as PropType<RatingSize>,
    default: undefined
  },
  active: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['click:rating'])

const total = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].filter(el => props.active ? true : el % 1 === 0)
const roundedScore = ref((Math.floor(props.value * 2)) / 2)

const checkedInput = (num: number): boolean => {
  if (!roundedScore.value) { return false }

  return roundedScore.value === num
}

const inputRef = ref(null)

if (props.active) {
  onClickOutside(inputRef, () => {
    const body = document.querySelector('body')
    if (body?.classList.contains('modal-open')) { return false }

    roundedScore.value = 0
  })
}

const classes = computed(() => {
  const list = ['rating', 'rating-group']

  if (props.size) { list.push(`rating--${props.size}`) }
  if (props.active) { list.push('rating--active') }
  if (roundedScore.value === 0) { list.push('rating--empty') }

  return list
})

const emitClick = (rating: number): void => {
  if (!props.active) { return }
  roundedScore.value = rating
  emit('click:rating', rating)
}
</script>

<style lang="postcss">
.rating {
  --rating-gap: var(--spacer-5xs);
  --rating-size: var(--spacer-xs);

  display: flex;
  width: max-content;
  margin: 0 calc(var(--rating-gap) * -1);
  padding-right: var(--rating-gap);
  pointer-events: none;

  &--empty {
    .rating__label .rating__icon::before {
      background: url("/assets/icons/general.svg#star") center / 112% 112%;
    }
  }

  &--active {
    pointer-events: all;

    &:not(:hover) {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        z-index: 2;
        width: 45px;
        height: 100%;
        background-color: rgb(0 0 0 / 0%);
        background-image: repeating-linear-gradient(
          to right,
          rgb(255 255 255 / 21%) 0%,
          rgb(255 255 255 / 75%) 50%,
          rgb(255 255 255 / 21%) 99%
        );
        background-position: 0% 0%;
        background-size: auto;
        background-repeat: repeat;
        transform: skewX(-45deg);
        animation: flare-animation 2s infinite linear;
      }
    }

    &.rating-group:hover .rating__label .rating__icon::before {
      background: url("/assets/icons/general.svg#star-yellow") center / 112%
        112% no-repeat;
    }

    .rating__input:hover ~ .rating__label .rating__icon::before {
      background: url("/assets/icons/general.svg#star") center / 112% 112%;
    }
  }

  &__input {
    position: absolute !important;
    left: -9999px !important;
  }

  &__icon {
    display: block;
    overflow: hidden;

    &[class*="i-star"]::before {
      display: block;
      width: var(--rating-size);
      height: var(--rating-size);
    }
  }

  &__label {
    padding: 0 0 0 var(--rating-gap);
    cursor: pointer;

    &--half {
      z-index: 2;
      width: calc(var(--rating-size) / 2);
      margin-right: calc((var(--rating-size) / 2) * -1);
      padding-right: 0;

      .rating__icon {
        width: calc(var(--rating-size) / 2);
      }
    }
  }
}

.rating__input:checked + .rating__label .i-star-yellow::before {
  background: url("/assets/icons/general.svg#star-yellow") center / 112% 112%;
}

.rating__input:checked ~ .rating__label .i-star-yellow::before {
  background: url("/assets/icons/general.svg#star") center / 112% 112%;
}

.rating-group {
  position: relative;
  display: inline-flex;
  overflow: hidden;
}

.rating--lg {
  --rating-gap: var(--spacer-3xs);
  --rating-size: var(--spacer-xl);
}

.rating--base {
  --rating-gap: var(--spacer-3xs);
  --rating-size: var(--spacer-base);
}

.rating--sm {
  --rating-gap: var(--spacer-4xs);
  --rating-size: var(--spacer-sm);
}

@keyframes flare-animation {
  0% {
    left: -150%;
  }

  100% {
    left: 150%;
  }
}
</style>
